/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const DigitalProductInnovationArticles = () => {
  const DigitalProductInnovationArticleImages = useStaticQuery(graphql`
      query DigitalProductInnovationArticleImages {
        tech: file(absolutePath: {regex: "/assets/images/TechRevolutionOfUsefuleness.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        nerd: file(absolutePath: {regex: "/assets/images/LearnFromNerdNation.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="spb-10 Articles_s border-bottom">
        <Row>
          <Col sm="12" lg="5" xl="4" className="titlef sp-5">
          	<h2>Dive more into digital product innovation</h2>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <a href="/read/technology-and-the-revolution-of-usefulness">
              <div className="Articles-img">
                <Image className="img-fluid" sizes={DigitalProductInnovationArticleImages.tech.childImageSharp.sizes} alt="Technology and the revolution of usefulness"/>
              </div>
              <div class="card-body">
                <h6 className="h3">Technology and the revolution of usefulness.</h6>
                <p>If you’ve been listening to the conversations that management teams have, you will have heard the words “digital disruption” a lot.</p>
              </div>
            </a>
          </Col>
          <Col sm="6">
            <a href="/read/what-your-business-can-learn-from-the-nerd-nation">
              <div className="Articles-img">
                <Image className="img-fluid" sizes={DigitalProductInnovationArticleImages.nerd.childImageSharp.sizes} alt="What your business can learn from the #NerdNation."/>
              </div>
              <div class="card-body">
                <h6 className="h3">What your business can learn from the #NerdNation.</h6>
                <p>They wear black hoodies, write code and give quirky and inspiring TED Talks. Some build annoyingly addictive social media networks and have movies made about them.</p>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    )
  }


export default DigitalProductInnovationArticles

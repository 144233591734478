/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const DigitalProductInnovationCasestudies = () => {
  const DigitalProductInnovationCasestudiesImages = useStaticQuery(graphql`
      query DigitalProductInnovationCasestudiesImages {
        ffs: file(absolutePath: {regex: "/assets/images/FidoThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        grow: file(absolutePath: {regex: "/assets/images/GrowNationThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="spb-10 Casestudies-s">
        <Container>
          <Row>
            <Col sm="12" lg="5" xl="4" className="titlef sp-5">
              <h2>Our digital product innovation work</h2>
            </Col>
           </Row>
           <Row>
              <Col sm="6">
                <div className="comingsoon">
                  <div className="project-img">
                    <Image className="img-fluid" sizes={DigitalProductInnovationCasestudiesImages.ffs.childImageSharp.sizes} alt="Frank Financial Services"/>
                  </div>
                  <small class="clientname ffs">Frank Financial Services</small>
                  <h6 className="h3">
                    Fun, quick and easy online credit reports get over 160,000 checks requested in the first 6 months. 
                  </h6>
                  <p className="technologies">
                    Branding, Strategy, Product Management, Content Creation, UI/UX Design, Front-End Development, Back-End Development
                  </p>
                </div>
              </Col>
              <Col sm="6">
                <a target="_blank" href="http://grownationfund.com/">
                  <div className="project-img">
                    <Image className="img-fluid" sizes={DigitalProductInnovationCasestudiesImages.grow.childImageSharp.sizes} alt="CHEERS Z VENTURES"/>
                  </div>
                  <small class="clientname cheersz">CHEERS Z VENTURES</small>
                  <h6 className="h3">
                    From an idea to over 2,000 rural farmers registered in 4 months.
                  </h6>
                  <p className="technologies">
                    Branding, Strategy, Product Management, UI/UX Design, Front-End Development, Back-End Development
                  </p>
                </a>
              </Col>
            </Row>
        </Container>
      </div>
    )
  }


export default DigitalProductInnovationCasestudies
